import authAxios from "./config/need-auth-axios";

class withdrawlSerives {
    getToken(id, value) {
        return authAxios.post("/payments/token", {
            from_currency_id: id,
            from_amount: value,
            to_currency_id: id,
            to_amount: value,
        });
    }

    withdrawal(requestedAmountInRial, merchantId) {
        return authAxios.post("/merchant/panel/withdrawal/wr", {
            requestedAmountInRial: requestedAmountInRial,
            merchant: {
                merchantId: merchantId,
            },
        });
    }
}

export default new withdrawlSerives();
