import { mapActions, mapGetters } from "vuex";
import * as types from "./../../../../store/types";
import walletService from "./../../../../services/walletService";
import userInfoService from "./../../../../services/userInfoService";
import merchantService from "../../../../services/merchantService";
import $formatNumber from "./../../../../mixins/formatNumber";
import $formData from "./../../../../mixins/fromDataGetter";
import Num2persian from "num2persian";
import NumberToWords from "number-to-words";
import currenciesService from "../../../../services/currenciesService";
import withdrawalService from "../../../../services/withdrawalService";

const walletList = () => import("../../../../components/walletList/index.vue");

export default {
  name: "wallets",
  components: {
    walletList
  },
  props: [],
  mixins: [$formatNumber, $formData],
  data() {
    return {
      loading: false,
      validate: {
        isvalid: true,
        loading: false,
        message: "شماره موبایل به درستی وارد نشده است"
      },
      merchantSetting: "",
      tableHeaders: ["نام ارز", "مقدار دارایی شما", "عملیات"],
      ValueToCalculate: ""
    };
  },
  computed: {
    ...mapGetters({
      wallets: types.GET_WALLET,
      currencies: types.GET_CURRENCIES
    }),
    toPersian() {
      if (this.ValueToCalculate) {
        parseFloat(this.ValueToCalculate);
        let toValue = `${this.ValueToCalculate}`.replace(/,/g, "");
        return Num2persian((toValue / 10).toFixed(0));
      } else {
        return 0;
      }
    },
    toEnglish() {
      if (!this.ValueToCalculate) return;
      let toValue = `${this.ValueToCalculate}`.replace(/,/g, "");
      return NumberToWords.toWords((toValue / 10).toFixed(0));
    }
  },
  mounted() {},
  created() {
    this.getInfoData();
    this.getWalletFunc();
    this.getMerchantSettingFunc();
    this.getCurrenciesFunc();
  },
  methods: {
    ...mapActions({
      userInfoAction: types.ACTION_USER_INFO,
      currenciesAction: types.ACTION_CURRENCIES,
      walletAction: types.ACTION_WALLET
    }),
    total() {
      this.ValueToCalculate = this.wallets.rialValue;
      this.ValueToCalculate = this.$formatNumber(this.ValueToCalculate);
    },
    getMerchantSettingFunc() {
      merchantService
        .getMerchantSettings(localStorage.getItem("bitrahAccessToken"))
        .then(response => {
          this.merchantSetting = response.data.calculateRialBalanceAtSettle;
        });
    },
    getCurrenciesFunc() {
      currenciesService
        .getCurrencies()
        .then(response => {
          this.currenciesAction(response.data);
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    getInfoData() {
      userInfoService
        .getUserInfo()
        .then(response => {
          this.userInfoAction(response.data);
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        })
        .finally(() => (this.loading = false));
    },
    getWalletFunc() {
      walletService
        .getWallet(localStorage.getItem("bitrahAccessToken"))
        .then(response => {
          this.walletAction(response.data);
        })
        .catch(() => {
          this.$bvToast.toast(this.$i18n.t("nav.error"), {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        })
        .finally(() => (this.loading = false));
    },
    formatNumberFunc() {
      if (
        this.ValueToCalculate === 0 ||
        this.ValueToCalculate === "0" ||
        this.ValueToCalculate === ""
      ) {
        this.ValueToCalculate = 0;
      }
      this.ValueToCalculate = this.$formatNumber(
        parseFloat(`${this.ValueToCalculate}`.replace(/,/g, ""))
      );
    },
    submit(requestedAmountInRial) {
      if (this.ValueToCalculate == 0) {
        this.$bvToast.toast(this.$i18n.t("merchant.enterValue"), {
          title: this.$i18n.t("toast.errorTitle"),
          noCloseButton: true,
          variant: "custom",
          bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
          headerClass: "dangerBackgroundColor dangerFontColor  rtl text-right",
          solid: false
        });
      } else {
        this.loading = true;
        withdrawalService
          .withdrawal(
            parseFloat(`${requestedAmountInRial}`.replace(/,/g, "")),
            localStorage.getItem("bitrahAccessToken")
          )
          .then(() => {
            this.ValueToCalculate = "";
            this.$bvToast.toast(this.$i18n.t("toast.succesfullOperation"), {
              title: this.$i18n.t("toast.successTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass:
                "successFontColor rtl text-right font-weight-bold py-3",
              headerClass:
                "successBackgroundColor successFontColor  rtl text-right",
              solid: false
            });
          })
          .catch(error => {
            this.$bvToast.toast(error.data.message, {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false
            });
          })
          .finally(() => (this.loading = false));
      }
    }
  }
};
