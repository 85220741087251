import authAxios from "./config/need-auth-axios";

class merchantService {
    getMerchantInfo(merchantID) {
        return authAxios.get("/manifest/board/merchant/" + merchantID);
    }

    getMerchantSettings(merchantID) {
        return authAxios.post("/merchant/panel/settings/rd", merchantID);
    }
}

export default new merchantService();
